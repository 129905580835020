import React, { type ReactNode } from "react";
import { type PropsWithChildren, useEffect, useState } from "react";

type CSRProps = {
  fallback?:ReactNode
}

export const CSR = ({ children,fallback }: PropsWithChildren<CSRProps>) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  return <>{mounted ? children : fallback}</>;
};
