import Image from "next/image";
import Link from "next/link";
import { useRef, type ElementRef, useEffect } from "react";
import logoSymbol from "/public/assets/pc/images/logo/logo-symbol.svg";
import logoLetter from "/public/assets/pc/images/logo/logo-letter.svg";
import styles from "./마켓봄_로고_Desktop.module.css";
import { useTracker } from "./util/Tracker";

export const 마켓봄_로고_Desktop = () => {
  const logoSymbolRef = useRef<ElementRef<"img">>(null);
  const logoLetterRef = useRef<ElementRef<"img">>(null);
  const tracker = useTracker();

  useEffect(() => {
    const trackscroll = () => {
      if (logoSymbolRef.current && logoLetterRef.current) {
        if (window.scrollY > 50) {
          logoSymbolRef.current.classList.add(styles["hide"] ?? "x");
          logoLetterRef.current.classList.add(styles["enlarge"] ?? "x");
        } else {
          logoSymbolRef.current.classList.remove(styles["hide"] ?? "x");
          logoLetterRef.current.classList.remove(styles["enlarge"] ?? "x");
        }
      }
    };
    window.addEventListener("scroll", trackscroll);
    return () => {
      window.removeEventListener("scroll", trackscroll);
    };
  }, [logoSymbolRef, logoLetterRef]);
  return (
    <Link
      href="/"
      onClick={() => tracker.trackingClick({ depth1: "GNB", depth2: "마켓봄" })}
      style={{ display: "flex" }}
      aria-label="마켓봄 홈으로 이동"
    >
      <Image
        className={styles["logo-symbol"]}
        src={logoSymbol}
        alt=""
        width={29.18}
        height={22.62}
        style={{ marginRight: 12.51 }}
        ref={logoSymbolRef}
      />
      <Image
        src={logoLetter}
        alt=""
        className={styles["logo-letter"]}
        ref={logoLetterRef}
      />
    </Link>
  );
};
