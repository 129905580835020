import type { PropsWithChildren } from "react";
import { NotoSans } from "../components/font";

export const DesktopLayout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <style jsx global>{`
        @media {
          html,
          body,
          div,
          span,
          applet,
          object,
          iframe,
          h1,
          h2,
          h3,
          h4,
          h5,
          h6,
          p,
          blockquote,
          pre,
          a,
          abbr,
          acronym,
          address,
          big,
          cite,
          code,
          del,
          dfn,
          em,
          img,
          ins,
          kbd,
          q,
          s,
          samp,
          small,
          strike,
          strong,
          sub,
          sup,
          tt,
          var,
          b,
          u,
          i,
          center,
          dl,
          dt,
          dd,
          ol,
          ul,
          li,
          fieldset,
          form,
          label,
          legend,
          table,
          caption,
          tbody,
          tfoot,
          thead,
          tr,
          th,
          td,
          article,
          aside,
          canvas,
          details,
          embed,
          figure,
          figcaption,
          footer,
          header,
          hgroup,
          menu,
          nav,
          output,
          ruby,
          section,
          summary,
          time,
          mark,
          audio,
          video {
            margin: 0;
            padding: 0;
            border: 0;
            font-size: 100%;
            font: inherit;
            vertical-align: baseline;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
          }
          /* HTML5 display-role reset for older browsers */
          article,
          aside,
          details,
          figcaption,
          figure,
          footer,
          header,
          hgroup,
          menu,
          nav,
          section {
            display: block;
          }
          body {
            line-height: 1.5;
          }
          ol,
          ul {
            list-style: none;
          }
          blockquote,
          q {
            quotes: none;
          }
          blockquote:before,
          blockquote:after,
          q:before,
          q:after {
            content: "";
            content: none;
          }
          table {
            border-collapse: collapse;
            border-spacing: 0;
          }
          a {
            text-decoration: none;
            color: inherit;
            cursor: pointer;
          }
          button {
            border: 0;
            background: none;
            cursor: pointer;
          }
          input {
            border-radius: 0;
          }
        }
      `}</style>
      <style jsx global>{`
        @charset "utf-8";

        /* =================================================
	* Common
================================================= */
        html {
          -webkit-text-size-adjust: 100%;
          /* scroll-behavior: smooth; */
          scroll-padding-top: 90px;
        }
        body {
          min-width: 1200px;
          font-size: 14px;
          color: #000;
          /* font-family: "Noto Sans KR", sans-serif; */
          font-family: ${NotoSans.style.fontFamily}, sans-serif;
          line-height: 1.3;
          letter-spacing: -1px;
        }
        body.scroll-fixed {
          overflow: hidden;
        }
        #wrap {
          position: relative;
        }
        * {
          box-sizing: border-box;
          -webkit-overflow-scrolling: touch;
          appearance: none;
          -webkit-appearance: none;
          outline: none;
        }
        .blind {
          position: absolute;
          overflow: hidden;
          clip: rect(0 0 0 0);
          margin: -1px;
          width: 1px;
          height: 1px;
        }
        .fr {
          float: right;
        }
        .fl {
          float: left;
        }
        .fn {
          float: none;
        }
        .cb:after {
          content: "";
          display: block;
          clear: both;
        }
        .bold {
          font-weight: bold;
        }
        .tl {
          text-align: left !important;
        }
        .tc {
          text-align: center !important;
        }
        .tr {
          text-align: right !important;
        }
        a:link {
          text-decoration: none;
        }
        a:visited {
          text-decoration: none;
        }
        a:hover {
          text-decoration: none;
        }
        button {
          outline: none;
          padding: 0px;
        }
        input,
        textarea,
        select,
        button {
          font-family: ${NotoSans.style.fontFamily}, sans-serif;
        }
        .m-show {
          display: none !important;
        }
        .table {
          display: table;
          width: 100%;
          height: 100%;
          text-align: center;
        }
        .table-cell {
          display: table-cell;
          vertical-align: middle;
        }
        .inner {
          width: 1180px;
          margin: 0 auto;
        }
        .open-sans {
          font-family: "Open Sans", sans-serif;
        }

        /* Font color */
        .fc1 {
          color: #fe6b37 !important;
        }

        /* Button */
        .btn.type1 {
          display: inline-block;
          width: 110px;
          height: 54px;
          background: #fff;
          border: 1px solid #666;
          border-radius: 27px;
          font-size: 18px;
          font-weight: bold;
          color: #000;
          line-height: 52px;
          text-align: center;
          transition: 0.2s ease-in-out;
        }
        .btn.type2 {
          display: inline-block;
          width: 110px;
          height: 54px;
          background: #402578;
          border: 1px solid #402578;
          border-radius: 27px;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          line-height: 52px;
          text-align: center;
          transition: 0.2s ease-in-out;
        }
        .btn.type3 {
          display: block;
          width: 220px;
          height: 70px;
          background: #333;
          border-radius: 3px;
          font-size: 21px;
          color: #fff;
          text-align: center;
          line-height: 70px;
          transition: 0.2s ease-in-out;
        }
        .btn.type4 {
          display: inline-block;
          width: 120px;
          height: 40px;
          background: #8461cf;
          border: 1px solid #8461cf;
          border-radius: 40px;
          font-size: 14px;
          color: #fff;
          line-height: 38px;
          text-align: center;
          transition: 0.2s ease-in-out;
        }
        .btn.type1:hover {
          background: #f4f4f4;
        }
        .btn.type2:hover {
          background: #2d1a54;
        }
        .btn.type3:hover {
          opacity: 0.8;
        }
        .btn.type4:hover {
          background: #7e4bcf;
          border-color: #7e4bcf;
        }

        /* Dot list */
        .dot-list li {
          position: relative;
          padding-left: 13px;
          font-size: 16px;
          color: #555;
        }
        .dot-list li + li {
          margin-top: 10px;
        }
        .dot-list li:before {
          content: "";
          position: absolute;
          top: 9px;
          left: 0;
          width: 3px;
          height: 3px;
          background: #555;
          border-radius: 3px;
        }

        /* Layer */
        .layer {
          /* display: none; */
          position: fixed;
          top: 0;
          left: 0;
          z-index: 1000;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.6);
          opacity: 0;
          transition: all 0.3s ease-in;
          pointer-events: none;
        }
        .layer.on {
          opacity: 1;
          pointer-events: all;
          transition: all 0.3s ease-in;
        }
        .layer .middle-wrap {
          display: table;
          width: 100%;
          height: 100%;
          text-align: center;
        }
        .layer .middle-wrap .middle {
          display: table-cell;
          vertical-align: middle;
        }
        .layer .content {
          position: relative;
          margin: 0 auto;
        }

        .layer-video .btn-close {
          position: absolute;
          top: -53px;
          right: 0;
          width: 33px;
          height: 33px;
          background: url("/public/assets/pc/images/btn/btn_close1.webp")
            no-repeat center center;
          text-indent: -9999em;
        }
        .layer-video .content {
          width: 820px;
          padding: 28px 30px 30px;
          background: #fff;
        }
        .layer-video .content .title {
          margin-bottom: 28px;
          font-size: 20px;
          color: #111;
          text-align: center;
        }
        .layer-distant {
          overflow: hidden;
          position: fixed;
          bottom: 183px;
          right: 44px;
          z-index: 100;
          width: 110px;
          height: 110px;
          border: 2px solid #a9ccff;
          border-radius: 55px;
        }
        .layer-distant a {
          display: block;
          width: 100%;
          height: 100%;
          padding-top: 66px;
          background: url("/public/assets/pc/images/pic/img_distant1.webp")
            no-repeat center center #35206a;
          font-size: 14px;
          font-weight: bold;
          color: #fff;
          text-align: center;
        }
        .layer-follow {
          position: fixed;
          bottom: 67px;
          right: 44px;
          z-index: 100;
          width: 110px;
          height: 110px;
          padding-top: 62px;
          background: url("/public/assets/pc/images/pic/img_cscenter1.webp")
            no-repeat center center #fe6b37;
          border-radius: 55px;
          box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.2);
          font-size: 14px;
          font-weight: bold;
          color: #fff;
          text-align: center;
          cursor: pointer;
        }
        .layer-cscenter .content {
          width: 750px;
          padding: 35px 40px 50px;
          background: #fff;
          text-align: left;
        }
        .layer-cscenter .content h2 {
          margin-bottom: 30px;
          font-size: 30px;
          font-weight: bold;
          color: #333;
          line-height: 1.4;
        }
        .layer-cscenter .btn-close {
          position: absolute;
          top: 43px;
          right: 29px;
          width: 23px;
          height: 23px;
          background: url("/public/assets/pc/images/icon/icon_close1.webp")
            no-repeat center center;
          text-indent: -9999em;
        }
        .layer-cscenter .row:after {
          content: "";
          display: block;
          clear: both;
        }
        .layer-cscenter .row + .row {
          margin-top: 17px;
        }
        .layer-cscenter .row .input-text {
          float: left;
        }
        .layer-cscenter .row .input-text + .input-text {
          margin: 0 0 0 30px;
        }
        .layer-cscenter .row .input-date {
          float: left;
          margin: 0 0 0 30px;
        }
        .layer-cscenter .row .input-text input {
          width: 320px;
        }
        .layer .btn-wrap {
          margin-top: 40px;
          font-size: 0;
          text-align: center;
        }
        .layer .btn-wrap a {
          display: inline-block;
          width: 180px;
          height: 50px;
          padding-top: 13px;
          background: #333;
          font-size: 16px;
          font-weight: 500;
          color: #fff;
          text-align: center;
        }
        .layer .btn-wrap a + a {
          margin-left: 10px;
        }

        /* Form */
        .input-text label {
          display: block;
          margin-bottom: 11px;
          font-size: 16px;
          color: #111;
        }
        .input-text input {
          display: block;
          width: 100%;
          height: 50px;
          padding: 0 15px;
          background: #eee;
          border: 1px solid #eee;
          font-size: 16px;
          color: #111;
        }
        .input-text textarea {
          display: block;
          width: 100%;
          height: 250px;
          padding: 12px 15px;
          background: #eee;
          border: 1px solid #eee;
          font-size: 16px;
          color: #111;
          resize: none;
        }
        .input-text input:focus,
        .input-text textarea:focus {
          background: #fff;
          border-color: #111;
        }
        .input-text input::placeholder,
        .input-text textarea::placeholder {
          font-size: 16px;
          color: #999;
        }
        .input-text input:-ms-input-placeholder {
          font-size: 16px;
          color: #999;
        }
        .input-text textarea:-ms-input-placeholder {
          font-size: 16px;
          color: #999;
        }
        .input-text + .input-text {
          margin-top: 19px;
        }
        .input-text.type2 {
          position: relative;
          padding-left: 142px;
        }
        .input-text.type2 .tit {
          position: absolute;
          top: 0;
          left: 0;
          font-size: 16px;
          font-weight: bold;
          color: #333;
          line-height: 35px;
        }
        .input-text.type2 .tit span {
          margin-right: 3px;
          color: #ff663f;
        }
        .input-text.type2 input {
          width: 280px;
          height: 35px;
          padding: 0 10px;
          background: #fff;
          border: 1px solid #979797;
          border-radius: 0;
        }
        .input-text.type2 input:-ms-input-placeholder {
          font-size: 16px;
          color: #999;
        }
        .input-text.type2 input:focus {
          border-color: #333;
        }
        .input-text.type2 input:focus::placeholder {
          color: #333;
        }
        .input-text.type2 input:read-only {
          background: #f2f2f2;
          color: #999;
        }
        .input-text.type2 input[readonly] {
          background: #f2f2f2;
          color: #999;
        }
        .input-text.type2 label {
          margin-bottom: 0;
        }
        .input-text.type2 label + label {
          margin-top: 7px;
        }
        .input-text.type2 + .input-text.type2 {
          margin-top: 7px;
        }
        .input-text label.btn-input input {
          float: left;
          width: 180px;
          margin-right: 5px;
        }
        .input-text label.btn-input:after {
          content: "";
          display: block;
          clear: both;
        }
        .input-text label.btn-input .btn-confirm {
          float: left;
          width: 95px;
          height: 35px;
          background: #35206a;
          border-radius: 3px;
          font-size: 14px;
          font-weight: bold;
          color: #fff;
        }
        .input-date .label {
          display: block;
          margin-bottom: 11px;
          font-size: 16px;
          color: #111;
        }
        .input-date .date-wrap:after {
          content: "";
          display: block;
          clear: both;
        }
        .input-date .date-wrap label {
          float: left;
        }
        .input-date .date-wrap input {
          display: block;
          width: 70px;
          height: 50px;
          padding: 0 15px;
          background: #eee;
          border: 1px solid #eee;
          font-size: 16px;
          color: #111;
          text-align: right;
        }
        .input-date .date-wrap input:focus {
          background: #fff;
          border-color: #111;
        }
        .input-date .date-wrap span {
          float: left;
          padding-left: 7px;
          font-size: 16px;
          color: #111;
          line-height: 50px;
        }
        .input-date .date-wrap span + label {
          margin-left: 23px;
        }

        /* 이벤트 배너 */
        /* .wrap-margin #header {
    position: absolute;
    left: 0 !important;
} */

        /* =================================================
    * Container
================================================= */

        /* Main */
        .main {
          scroll-behavior: smooth;
        }
        .main .section1 {
          height: 780px;
          overflow: hidden;
        }
        .main .section1 .inner {
          position: relative;
          padding-top: 200px;
        }
        .franchise .main .section1 .inner {
          position: relative;
          padding-top: 160px;
        }
        .main .section1 .pic {
          position: absolute;
          top: 149px;
          right: -50px;
        }
        .main .section1 .saleTag {
          position: absolute;
          bottom: -45px;
          top: 529px;
          right: -133px;
          width: 186px;
          height: 186px;

          background-size: 100% 100%;
        }
        .main .section1 dl dt {
          margin-bottom: 24px;
          font-size: 20px;
          color: #000;
          line-height: 1.2;
          letter-spacing: -0.03em;
        }
        .main .section1 dl dt strong {
          font-weight: bold;
        }
        .main .section1 dl dd {
          position: relative;
          margin-bottom: 40px;
          font-size: 48px;
          font-weight: bold;
          color: #000;
          line-height: 60px;
          letter-spacing: -0.03em;
        }
        .main .section1 dl dd:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 299px;
          height: 18px;
          background: rgba(254, 107, 56, 0.2);
        }
        .franchise .main .section1 dl dd:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          width: 450px;
          height: 18px;
          background: rgba(254, 107, 56, 0.2);
        }
        .main .section1 .btn-wrap a {
          display: block;
          width: 270px;
          height: 78px;
          padding-top: 24px;
          background: #fe6b37;
          border-radius: 40px;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          text-align: center;
          line-height: 1.2;
          transition: 0.2s ease-in-out;
        }
        .main .section1 .btn-wrap a:hover {
          opacity: 0.8;
        }
        .main .section2 {
          padding: 90px 0;
          background: #f3f5f9;
          text-align: center;
        }
        .main .section2 dl dt {
          margin-bottom: 50px;
          font-size: 40px;
          font-weight: bold;
          color: #000;
          text-align: center;
          line-height: 40px;
          letter-spacing: -2px;
        }
        .main .section2 dl dd {
          margin-bottom: 15px;
          font-size: 32px;
          color: #000;
          line-height: 44px;
          letter-spacing: -1.6px;
          text-align: center;
        }
        .main .section2 dl dd .highlight {
          display: inline-block;
          position: relative;
          margin-top: 20px;
        }
        .main .section2 dl dd .highlight:before {
          content: "";
          position: absolute;
          bottom: -5px;
          left: -11px;
          right: -11px;
          z-index: 1;
          height: 22px;
          background: #7245b6;
          opacity: 0.2;
        }
        .main .section2 dl dd .highlight {
          position: relative;
          z-index: 2;
          font-size: 34px;
          font-weight: bold;
          color: #000;
          text-align: center;
          line-height: 34px;
        }
        .main .section2 dl dd .highlight p span {
          font-weight: bold;
          color: #402578;
        }
        .main .section2 dl dd .highlight p span em {
          font-style: italic;
        }
        .main .section2 .pic {
          text-align: center;
        }
        .main .section2 .pic a {
          display: inline-block;
        }
        .main .section2 .pic img {
          display: block;
          margin: 0 auto;
        }
        .main .section2 .pic .txt {
          display: none;
        }
        .main .section2 ul li.slick-active a img{
          border: 4px solid #FE6B37;
          border-radius: 10px;
        }
        .main .section3 {
          padding: 95px 0 0;
        }
        .main .section3 h3 {
          font-size: 40px;
          font-weight: bold;
          color: #000;
          text-align: center;
          line-height: 1;
        }
        .main .section3 .row {
          height: 480px;
          padding: 115px 0 0 60px;
        }
        .main .section3 .row + .row {
          border-top: 1px solid #ccc;
        }
        .franchise .main .section3 .row {
          border-top: none;
          border-bottom: 1px solid #ccc;
        }
        .main .section3 .row dl dt {
          margin-bottom: 25px;
          font-size: 32px;
          color: #000;
          line-height: 44px;
        }
        .main .section3 .row dl dd {
          font-size: 20px;
          color: #000;
          line-height: 30px;
        }
        .main .section3 .row.row-right {
          padding-left: 690px;
        }
        .main .section3 .row.type8 {
          padding-top: 148px;
        }
        .main .section3 .row.type9 {
          height: 540px;
        }
        .franchise .main .section3 .row.type9 {
          height: 480px;
        }
        .main .section4 {
          padding: 95px 0 140px;
          background: #353959;
        }
        .main .section4 .inner {
          width: 1040px;
        }
        .main .section4 h3 {
          margin-bottom: 15px;
          font-size: 40px;
          font-weight: bold;
          color: #fff;
          text-align: center;
          line-height: 1.2;
        }
        .main .section4 .desc {
          margin-bottom: 65px;
          font-size: 24px;
          color: #fff;
          text-align: center;
        }
        .main .section4 .col-wrap:after {
          content: "";
          display: block;
          clear: both;
        }
        .main .section4 .col-wrap .col {
          float: left;
          width: 480px;
          padding: 42px 60px 36px;
          background: #fff;
          border-radius: 18px;
        }
        .main .section4 .col-wrap .col + .col {
          float: right;
        }
        .main .section4 .col-wrap .col .row + .row {
          margin: 40px -60px 0;
          padding-top: 31px;
          border-top: 1px dashed #999;
        }
        .main .section4 .col-wrap .col .num {
          display: block;
          margin-bottom: 20px;
          font-size: 60px;
          font-weight: bold;
          color: rgba(105, 166, 223, 0.5);
          text-align: center;
          line-height: 1;
        }
        .main .section4 .col-wrap .col dl dt {
          padding-bottom: 145px;
          font-size: 32px;
          font-weight: bold;
          color: #000;
          line-height: 42px;
          text-align: center;
        }

        .main .section4 .col-wrap .col dl dd {
          position: relative;
          margin-bottom: 23px;
          font-size: 20px;
          color: #000;
          text-align: center;
          line-height: 32px;
          letter-spacing: -1px;
        }
        .main .section4 .col-wrap .col p {
          margin-bottom: 10px;
          font-size: 20px;
          color: #000;
          text-align: center;
          line-height: 32px;
        }
        .main .section4 .col-wrap .col .btn-wrap {
          text-align: center;
        }
        .main .section4 .col-wrap .col .btn-wrap .btn-start {
          display: block;
          width: 100%;
          height: 60px;
          margin-top: 10px;
          padding-top: 18px;
          background: #402578;
          border-radius: 30px;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          text-align: center;
          transition: 0.2s ease-in-out;
        }
        .main .section4 .col-wrap .col .btn-wrap .btn-start:hover {
          background: #2d1a54;
        }
        .main .section4 .col-wrap .col .btn-wrap .btn-detail {
          display: inline-block;
          padding-right: 30px;
          font-size: 20px;
          font-weight: bold;
          color: #000;
          line-height: 28px;
        }

        .main .section5 {
          padding: 89px 0 114px;
          background: #f3f5f9;
        }
        .main .section5 h3 {
          margin-bottom: 40px;
          font-size: 40px;
          font-weight: bold;
          color: #000;
          text-align: center;
          line-height: 1;
        }
        .main .section5 .store-box {
          height: 220px;
          background: #fff;
          border-radius: 20px;
        }
        .franchise .main .section5 .store-box {
          width: fit-content;
        }
        .main .section5 .store-box:after {
          content: "";
          display: block;
          clear: both;
        }
        .main .section5 .store-box .col {
          position: relative;
          float: left;
          width: 580px;
          height: 100%;
          padding-top: 50px;
          text-align: center;
        }
        .main .section5 .store-box .col + .col {
          width: 460px;
        }
        .main .section5 .store-box .col + .col:before {
          content: "";
          position: absolute;
          top: 50%;
          left: 0;
          width: 1px;
          height: 120px;
          margin-top: -60px;
          background: #dce1eb;
        }
        .main .section5 .store-box .col h4 {
          margin-bottom: 27px;
          font-size: 24px;
          font-weight: bold;
          color: #000;
          line-height: 1;
        }
        .main .section5 .store-box .col .btn-wrap {
          font-size: 0;
          text-align: center;
        }
        .main .section5 .store-box .col .btn-wrap a {
          display: inline-block;
        }
        .main .section5 .store-box .col .btn-wrap a img {
          display: block;
        }
        .main .section5 .store-box .col .btn-wrap a + a {
          margin-left: 10px;
        }
        .main .section6 .inner {
          padding: 95px 0 290px;
        }
        .main .section6 h3 {
          margin-bottom: 60px;
          font-size: 40px;
          font-weight: bold;
          color: #000;
          text-align: center;
          line-height: 52px;
        }
        .main .section6 .col-wrap {
          width: 820px;
          margin: 0 auto;
        }
        .main .section6 .col-wrap:after {
          content: "";
          display: block;
          clear: both;
        }
        .main .section6 .col-wrap .col {
          float: left;
          width: 360px;
        }
        .main .section6 .col-wrap .col + .col {
          margin-left: 100px;
        }
        .main .section6 .col-wrap .col h4 {
          margin-bottom: 10px;
          padding-bottom: 18px;
          border-bottom: 2px solid #000;
          font-size: 24px;
          font-weight: bold;
        }
        .main .section6 .col-wrap .col h4 span {
          font-size: 18px;
          font-weight: 400;
        }
        .main .section6 .col-wrap .col .counter {
          font-size: 80px;
          font-weight: 600;
          color: #000;
          line-height: 1.2;
        }
        .main .section7 {
          padding: 100px 0 142px;
        }
        .main .section7 h3 {
          margin-bottom: 55px;
          font-size: 40px;
          font-weight: bold;
          color: #000;
          text-align: center;
          line-height: 52px;
        }
        .main .section7 ul {
          overflow: hidden;
        }
        .main .section7 ul li {
          float: left;
          width: 370px;
        }
        .main .section7 ul li + li {
          margin-left: 35px;
        }
        .main .section7 ul li .pic {
          display: table;
          overflow: hidden;
          position: relative;
          width: 100%;
          height: 240px;
          margin-bottom: 24px;

          border-radius: 18px;
        }
        /* .franchise .main .section7 ul li .pic {
        margin-bottom: 24px;
    } */
        .main .section7 ul li .pic dl {
          display: table-cell;
          position: relative;
          z-index: 2;
          vertical-align: middle;
          text-align: center;
          background: #00000088;
        }
        .main .section7 ul li .pic dl dt {
          position: relative;
          margin-bottom: 18px;
          padding-bottom: 16px;
          font-size: 32px;
          font-weight: bold;
          color: #fff;
          line-height: 1;
        }
        .main .section7 ul li .pic dl dt:after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 200px;
          height: 1px;
          margin-left: -100px;
          background: #fff;
        }
        .main .section7 ul li .pic dl dd {
          font-size: 18px;
          color: #fff;
        }

        .main .section7 ul li .text {
          padding-left: 20px;
        }
        .main .section7 ul li .text dt {
          position: relative;
          margin-bottom: 30px;
          font-size: 24px;
          font-weight: bold;
          color: #000;
          line-height: 34px;
        }
        .main .section7 ul li .text dt:before {
          content: "";
          position: absolute;
          top: -25px;
          left: 0;
          width: 21px;
          height: 18px;
          /* background: url('/public/assets/pc/images/pic/img_quotes1.webp') no-repeat center center;
        background-size: 100% 100%; */
        }
        .main .section7 ul li .text dd {
          font-size: 18px;
          color: #555;
          line-height: 28px;
        }
        .main .section8 {
          padding: 110px 0 52px;
          background: #353959;
        }
        .main .section8 .inner {
          overflow: hidden;
        }
        .main .section8 h3 {
          margin-bottom: 55px;
          font-size: 40px;
          font-weight: bold;
          color: #fff;
          text-align: center;
          line-height: 52px;
        }
        .main .section8 .notice-wrap {
          width: calc(100% + 120px);
          margin-left: -60px;
          padding-bottom: 70px;
        }
        .main .section8 .notice-wrap .item {
          display: flex !important;
          margin: 0px 60px;
          gap: 60px;
          /* width: 1280px !important; */
        }

        /* .main .section8 .notice-wrap .item:after {
    content: '';
    display: block;
    clear: both;
} */
        .main .section8 .notice-wrap .item a {
          width: 560px;
          height: 270px;
          padding: 35px 40px 0;
          background: url("/public/assets/pc/images/icon/icon_move_link1.webp")
            no-repeat left 40px bottom 35px #fff;
          border-radius: 18px;
          transition: 0.2s ease-in-out;
        }
        .main .section8 .notice-wrap .item .title {
          display: block;
          margin-bottom: 40px;
          font-size: 24px;
          font-weight: bold;
          color: #000;
          line-height: 34px;
          transition: 0.2s ease-in-out;
        }
        .main .section8 .notice-wrap .item .date {
          display: block;
          margin-bottom: 5px;
          font-size: 16px;
          color: #555;
          transition: 0.2s ease-in-out;
        }
        .main .section8 .notice-wrap .item .desc {
          display: block;
          font-size: 18px;
          color: #555;
          line-height: 28px;
          transition: 0.2s ease-in-out;
        }
        .main .section8 .notice-wrap .item a:hover {
          background-color: #fe6b37 !important;
          box-shadow: 11px 11px 20px rgba(43, 29, 72, 0.2);
        }
        .main .section8 .notice-wrap .item a:hover .title,
        .main .section8 .notice-wrap .item a:hover .date,
        .main .section8 .notice-wrap .item a:hover .desc {
          color: #fff;
        }
        .slick-nav {
          display: flex;
          justify-content: center;
        }
        .main .section8 .slick-prev {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          text-indent: -9999em;
          transition: 0.2s ease-in-out;
          left: 42%;
          top: initial;
          transform: initial;
          bottom: 0px;
        }
        .main .section8 .slick-next {
          width: 40px;
          height: 40px;
          border-radius: 20px;
          text-indent: -9999em;
          transition: 0.2s ease-in-out;
          left: 55%;
          top: initial;
          transform: initial;
          bottom: 0px;
        }
        .main .section8 .slick-prev::before,
        .main .section8 .slick-next::before {
          content: "";
        }
        .main .section8 .slick-prev:hover,
        .main .section8 .slick-next:hover {
          background-color: #fff !important;
        }
        .main .section8 .slide-wrap {
          position: relative;
        }
        .main .section8 .slide-wrap .pager {
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 110px;
          margin-left: -55px;
          font-size: 20px;
          font-weight: 500;
          color: #999ba4;
          text-align: center;
          line-height: 40px;
        }
        .main .section8 .slide-wrap .pager .current {
          color: #fff;
        }
        .main .section9 {
          position: relative;
          padding: 70px 0 170px;
          background: #f0f2f6;
        }
        .main .section9:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 322px;
          background: #353959;
        }
        .main .section9 .inner {
          position: relative;
          z-index: 2;
        }
        .main .section9 .inner:after {
          content: "";
          display: block;
          clear: both;
        }
        .main .section9 .text {
          float: left;
          padding-top: 68px;
        }
        .main .section9 .text h3 {
          margin-bottom: 10px;
          font-size: 24px;
          font-weight: bold;
          color: #fff;
          line-height: 34px;
        }
        .main .section9 .text .desc {
          font-size: 40px;
          font-weight: bold;
          color: #fff;
        }
        .main .section9 .text .contact {
          margin-top: 175px;
        }
        .main .section9 .text .contact .tel {
          position: relative;
          height: 60px;
          padding-left: 85px;
        }
        .main .section9 .text .contact .telIcon {
          content: "";
          position: absolute;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          top: 50%;
          left: 0;
          width: 60px;
          height: 60px;
          margin-top: -30px;
          border-radius: 30px;
        }
        .main .section9 .text .contact .tel strong {
          display: block;
          font-size: 34px;
          font-weight: bold;
          color: #000;
          line-height: 1.2;
        }
        .main .section9 .text .contact .tel span {
          font-size: 18px;
          color: #555;
          line-height: 1.2;
        }
        .main .section9 .text .contact .email {
          position: relative;
          margin-top: 20px;
          height: 60px;
          padding: 11px 0 0 85px;
          font-size: 24px;
          font-weight: bold;
          color: #000;
          line-height: 1.4;
        }
        .main .section9 .text .contact .emailIcon {
          content: "";
          position: absolute;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          top: 50%;
          left: 0;
          width: 60px;
          height: 60px;
          margin-top: -30px;
          border-radius: 30px;
        }
        .main .section9 .text .contact .location {
          position: relative;
          margin-top: 16px;
          padding: 0 0 0 85px;
          font-size: 24px;
          font-weight: bold;
          color: #000;
          line-height: 34px;
        }
        .main .section9 .text .contact .locationIcon {
          content: "";
          position: absolute;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          background: #fff;
          top: 50%;
          left: 0;
          width: 60px;
          height: 60px;
          margin-top: -30px;
          border-radius: 30px;
        }
        .main .section9 .insert-area {
          position: absolute;
          top: 0;
          right: 0;
          width: 620px;
          padding: 38px 50px 50px;
          background: #fff;
          border-radius: 18px;
          box-shadow: 11px 11px 20px rgba(43, 29, 72, 0.15);
        }
        .main .section9 .insert-area .row + .row {
          margin-top: 17px;
        }
        .main .section9 .insert-area .row:after {
          content: "";
          display: block;
          clear: both;
        }
        .main .section9 .insert-area .row .col {
          float: left;
          width: 250px;
        }
        .main .section9 .insert-area .row .col + .col {
          margin-left: 20px;
        }
        .main .section9 .insert-area .row .label {
          display: block;
          margin-bottom: 10px;
          font-size: 18px;
          color: #000;
        }
        .main .section9 .insert-area .row input {
          width: 100%;
          height: 50px;
          padding: 0 20px;
          background: #f0f2f6;
          border: 0;
          border-radius: 8px;
          font-size: 18px;
        }
        .main .section9 .insert-area .row textarea {
          width: 100%;
          height: 300px;
          padding: 20px;
          background: #f0f2f6;
          border: 0;
          border-radius: 8px;
          font-size: 18px;
          resize: none;
        }
        .main .section9 .insert-area .btn-wrap {
          margin-top: 30px;
          text-align: center;
        }
        .main .section9 .insert-area .btn-wrap .btn-send {
          width: 280px;
          height: 50px;
          background: #fff;
          border: 1px solid #000;
          border-radius: 25px;
          font-size: 18px;
          font-weight: bold;
        }

        /* Service */
        .service-infomation .section1 {
          position: relative;
          height: 260px;
        }
        .service-infomation .section1 .inner {
          display: table;
          position: relative;
          height: 100%;
        }
        .service-infomation .section1 .inner dl {
          display: table-cell;
          vertical-align: middle;
          text-align: center;
        }
        .service-infomation .section1 .inner dl dt {
          margin-bottom: 30px;
          font-size: 60px;
          font-weight: bold;
          color: #000;
          line-height: 1;
        }
        .service-infomation .section1 .inner dl dd {
          font-size: 20px;
          color: #000;
          line-height: 32px;
        }
        .service-infomation .section1 .inner .btn-prev {
          position: absolute;
          top: 50%;
          left: 50px;
          width: 30px;
          height: 60px;
          margin-top: -30px;
          background: url("/public/assets/pc/images/icon/icon_left3.webp")
            no-repeat center center;
          text-indent: -9999em;
        }
        .service-infomation .section1 .inner .btn-next {
          position: absolute;
          top: 50%;
          right: 50px;
          width: 30px;
          height: 60px;
          margin-top: -30px;
          background: url("/public/assets/pc/images/icon/icon_right4.webp")
            no-repeat center center;
          text-indent: -9999em;
        }
        .service-infomation .section2 {
          padding: 95px 0 0;
        }
        .service-infomation .section2 h3 {
          margin-bottom: 50px;
          font-size: 40px;
          font-weight: bold;
          color: #000;
          text-align: center;
          line-height: 1;
        }
        .service-infomation .section2 .row {
          height: 480px;
          padding: 105px 0 0 60px;
        }
        .service-infomation .section2 .row + .row {
          border-top: 1px solid #ccc;
        }
        .service-infomation .section2 .row .num {
          display: block;
          margin-bottom: 10px;
          font-size: 48px;
          font-weight: 700;
          color: #69a6df;
          line-height: 1;
          opacity: 0.5;
        }
        .service-infomation .section2 .row dl dt {
          margin-bottom: 30px;
          font-size: 32px;
          color: #000;
          line-height: 44px;
        }
        .service-infomation .section2 .row dl dd {
          font-size: 20px;
          color: #000;
          line-height: 30px;
        }
        .service-infomation .section2 .row.row-right {
          padding-left: 690px;
        }
        .service-infomation .section3 {
          padding: 99px 0 150px;
          background: #f3f5f9;
        }
        .service-infomation .section3 h3 {
          margin-bottom: 50px;
          font-size: 40px;
          font-weight: bold;
          color: #000;
          text-align: center;
          line-height: 1;
        }
        .service-infomation .function-list .row {
          overflow: hidden;
          position: relative;
          padding: 40px 40px 40px 294px;
          background: #fff;
          border-radius: 16px;
        }
        .service-infomation .function-list .row + .row {
          margin-top: 20px;
        }
        .service-infomation .function-list .row h4 {
          display: table;
          position: absolute;
          top: 0;
          left: 0;
          width: 255px;
          height: 100%;
          font-size: 24px;
          font-weight: bold;
          color: #000;
          text-align: center;
          line-height: 1;
          letter-spacing: -1.2px;
        }
        .service-infomation .function-list .row .cont:after {
          content: "";
          display: block;
          clear: both;
        }
        .service-infomation .function-list .row .cont .col {
          float: left;
          width: 33.3%;
        }
        .service-infomation .function-list .row .cont .col li {
          position: relative;
          padding-left: 14px;
          font-size: 18px;
          color: #000;
          line-height: 30px;
        }
        .service-infomation .function-list .row .cont .col li:before {
          content: "";
          position: absolute;
          top: 13px;
          left: 0;
          width: 4px;
          height: 4px;
          background: #000;
          border-radius: 4px;
        }
        .service-infomation .function-list .row .cont .col li + li {
          margin-top: 10px;
        }
        .service-infomation .function-list .row .cont .col h5 {
          margin-bottom: 10px;
          font-size: 18px;
          font-weight: bold;
          color: #fe6b37;
          line-height: 30px;
        }
        .service-infomation .function-list .row.type1 h4 {
          background: #d5f2ef;
        }
        .service-infomation .function-list .row.type2 h4 {
          background: #d4e8f1;
        }
        .service-infomation .function-list .row.type3 h4 {
          background: #d4e1f3;
        }
        .service-infomation .function-list .row.type4 h4 {
          background: #dbdaf3;
        }
        .service-infomation .function-list .row.type5 h4 {
          background: #eddcf4;
        }

        .service-infomation.service-marketbom .section1 {
          background: #fbf9f6;
        }
        .service-infomation.service-marketbom .section2 .row.type5 {
          height: 523px;
        }
        .service-infomation.service-marketbomdome .section3 {
          padding-bottom: 180px;
        }
        .service-infomation.service-marketbomdome
          .function-list
          .row:first-child
          .col:first-child
          h4 {
          left: 31px;
        }
        .service-infomation .btn-wrap {
          margin-top: 100px;
        }
        .service-infomation .btn-wrap a {
          display: block;
          width: 560px;
          height: 150px;
          margin: 0 auto;
          padding: 35px 70px 0;
          border-radius: 20px;
          box-shadow: 5px 8.7px 20px 0 rgba(42, 35, 87, 0.1);
          font-size: 32px;
          font-weight: bold;
          color: #fff;
        }
        .service-infomation .btn-wrap a span {
          display: block;
          margin-top: 5px;
          font-size: 24px;
          font-weight: 300;
          color: #fff;
          line-height: 34px;
          letter-spacing: -1px;
        }

        /* 이용가이드 */
        .usage-guide .section1 {
          height: 260px;
          background: #f6f6f7;
        }
        .usage-guide .section1 .inner {
          height: 100%;
          padding-top: 95px;
        }
        .usage-guide .section1 h4 {
          font-size: 60px;
          font-weight: bold;
          color: #000;
          text-align: center;
          line-height: 1;
        }
        .usage-guide .section2 {
          padding: 100px 0 200px;
        }
        .usage-guide .section2 .inner {
          position: relative;
          min-height: 710px;
          padding-left: 280px;
        }
        .usage-guide .section2 .menu-wrap {
          position: absolute;
          top: 0;
          left: 0;
        }
        .usage-guide .section2 .menu-wrap li + li {
          margin-top: 10px;
        }
        .usage-guide .section2 .menu-wrap li button {
          display: inline-flex;
          width: 180px;
          height: 50px;
          padding: 15px 0 0 39px;
          border-radius: 25px;
          font-size: 20px;
          color: #000;
          line-height: 20px;
          letter-spacing: -1px;
        }
        .usage-guide .section2 .menu-wrap li.on button {
          background: #010101;
          color: #fff;
        }
        .usage-guide .section2 .content h3 {
          display: block;
          margin-bottom: 55px;
          padding-bottom: 28px;
          border-bottom: 2px solid #000;
          font-size: 40px;
          font-weight: bold;
          color: #000;
          line-height: 1;
        }
        .usage-guide .section2 .menu-cont {
          /* display: none; */
        }
        .usage-guide .section2 .menu-cont.on {
          display: block;
        }
        .usage-guide .section2 .menu-cont ul {
          overflow: hidden;
          width: calc(100% + 40px);
          margin-left: -40px;
          font-size: 0;
        }
        .usage-guide .section2 .menu-cont ul li {
          display: inline-block;
          width: calc(50% - 40px);
          margin: 0 0 40px 40px;
          vertical-align: top;
        }
        .usage-guide .section2 .menu-cont ul li h4 {
          margin-bottom: 15px;
          font-size: 24px;
          font-weight: bold;
          color: #000;
        }
        .usage-guide .section2 .menu-cont ul li p {
          font-size: 18px;
          color: #000;
          line-height: 30px;
        }
        .usage-guide .section2 .menu-cont .video-area {
          margin-top: 40px;
        }
        .usage-guide .section2 .menu-cont .video-area h4 {
          margin-bottom: 25px;
          font-size: 32px;
          font-weight: bold;
          color: #000;
          line-height: 1;
        }
        .usage-guide .section2 .menu-cont .video-area ul {
          overflow: hidden;
          width: calc(100% + 40px);
          margin-left: -40px;
        }
        .usage-guide .section2 .menu-cont .video-area ul li {
          float: left;
          width: calc(50% - 40px);
          margin: 0 0 0 40px;
        }
        .usage-guide .section2 .menu-cont .video-area ul li a {
          display: block;
          width: 100%;
        }
        .usage-guide .section2 .menu-cont .video-area ul li img {
          display: block;
          width: 100%;
        }
        .usage-guide .price-area {
          margin-top: 235px;
        }
        .usage-guide .case-product {
          margin-bottom: 31px;
          padding: 0 27px 27px;
          background: #e9eaf9;
        }
        .case-product-header {
          display: flex;
          align-items: center;
          height: 118px;
        }
        .case-product-header .row {
          font-size: 22px;
          font-weight: bold;
          color: #343e5a;
          text-align: center;
          letter-spacing: -0.05em;
        }
        .case-product-header .row span {
          display: block;
          font-size: 16px;
          font-weight: 400;
          color: rgba(52, 62, 90, 0.7);
        }
        .case-product-header .row:nth-child(1) {
          width: 337px;
        }
        .case-product-header .row:nth-child(2) {
          width: 194px;
          margin-left: 57px;
        }
        .case-product-header .row:nth-child(3) {
          width: 260px;
        }
        .case-product-contents .col {
          display: flex;
          align-items: center;
          padding: 19px 0;
          background: #fff;
          font-size: 20px;
          color: #343e5a;
          text-align: center;
          letter-spacing: -0.05em;
        }
        .case-product-contents .col + .col {
          margin-top: 8px;
        }
        .case-product-contents .col h4 {
          flex: 0 1 auto;
          width: 230px;
          font-weight: bold;
        }
        .case-product-detail {
          line-height: 40px;
        }
        .case-product-detail .row {
          display: flex;
          align-items: center;
        }
        .case-product-detail .row span:nth-child(1) {
          flex: 0 1 auto;
          width: 105px;
        }
        .case-product-detail .row span:nth-child(2) {
          flex: 0 1 auto;
          width: 194px;
          margin-left: 57px;
        }
        .case-product-detail .row span:nth-child(3) {
          flex: 0 1 auto;
          width: 260px;
          padding-right: 76px;
          font-size: 24px;
          text-align: right;
        }
        .case-product-detail .row span strong {
          margin-right: 3px;
          font-weight: bold;
        }

        .usage-guide .pro-price-detail h4 {
          margin-bottom: 20px;
          font-size: 20px;
          font-weight: 500;
          color: #343e5a;
          letter-spacing: -1px;
        }
        .usage-guide .pro-price-detail h4 span {
          margin-left: 5px;
          font-size: 14px;
          font-weight: 400;
          letter-spacing: -0.7px;
          vertical-align: middle;
        }
        .usage-guide .pro-price-detail table {
          width: 100%;
          padding: 23px 16px;
          background: #f6f6f7;
          border-collapse: separate;
        }
        .usage-guide .pro-price-detail table th,
        .usage-guide .pro-price-detail table td {
          padding: 0 10px;
          font-size: 16px;
          font-weight: 500;
          color: #343e5a;
          text-align: center;
          letter-spacing: -0.48px;
        }
        .usage-guide .pro-price-detail table th:first-child {
          padding-right: 20px;
          border-right: 1px solid #c0c0c0;
          font-size: 18px;
          font-weight: 500;
          color: #343e5a;
          letter-spacing: -0.9px;
        }
        .usage-guide .pro-price-detail table tr:first-child th {
          padding-bottom: 10px;
        }
        .usage-guide .pro-price-detail table tr:last-child th,
        .usage-guide .pro-price-detail table tr:last-child td {
          padding-top: 10px;
        }
        .usage-guide .pro-price-detail table th:nth-child(2),
        .usage-guide .pro-price-detail table td:nth-child(2) {
          padding-left: 20px;
        }

        /* Join Us */
        .joinus {
          padding: 182px 0 135px;
          background: #f7f8f9;
        }
        .joinus .signup-title {
          padding: 110px 0 0;
          background: url("/public/assets/pc/images/icon/icon_home1.webp")
            no-repeat center top;
          text-align: center;
        }
        .joinus .signup-title dt {
          margin-bottom: 20px;
          font-size: 40px;
          font-weight: bold;
          color: #111;
        }
        .joinus .signup-title dd {
          margin-bottom: 65px;
          font-size: 20px;
          color: #555;
          line-height: 1.6;
        }
        .version-type {
          display: table;
          table-layout: fixed;
          width: 100%;
          margin-bottom: 60px;
        }
        .version-type .item {
          display: table-cell;
          position: relative;
          width: 33.3%;
          padding: 150px 40px 160px;
          background: #fff;
          height: 100%;
        }
        .version-type .item.plus {
          padding: 148px 38px 158px;
          border: 2px solid #fe6b37;
        }
        .version-type .item .version-name {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 138px;
          padding-top: 22px;
          background: #35206a;
          text-align: center;
        }
        .version-type .item.plus .version-name {
          height: 136px;
          padding-top: 20px;
          background: #fe6b37;
        }
        .version-type .item .version-name strong {
          display: block;
          margin-bottom: 5px;
          font-size: 30px;
          font-weight: 300;
          color: #fff;
        }
        .version-type .item .version-name strong em {
          font-weight: bold;
        }
        .version-type .item .version-name span {
          display: block;
          font-size: 16px;
          color: #fff;
          line-height: 1.6;
        }
        .version-type .item .price {
          margin-bottom: 25px;
          padding-bottom: 20px;
          border-bottom: 1px solid #c1c1c1;
          text-align: center;
        }
        .version-type .item .price strong {
          display: block;
          font-size: 34px;
          color: #333;
          line-height: 62px;
        }
        .version-type .item .price strong em {
          display: inline-block;
          font-size: 48px;
          font-weight: 900;
          vertical-align: top;
        }
        .version-type .item .price span {
          display: block;
          font-size: 16px;
          color: #333;
        }
        .version-type .item ul li strong {
          display: block;
          margin-bottom: 5px;
          font-size: 15px;
          font-weight: 500;
          color: #111;
        }
        .version-type .item ul li span {
          display: block;
          font-size: 15px;
          font-weight: 300;
          color: #555;
        }
        .version-type .item ul li {
          margin-bottom: 15px;
        }
        .version-type .item .notice {
          font-size: 15px;
          font-weight: 500;
          color: #fe6b37;
        }
        .version-type .item .btn-join {
          position: absolute;
          bottom: 50px;
          left: 50%;
          width: 260px;
          height: 60px;
          margin-left: -130px;
          background: #333;
          border-radius: 3px;
          font-size: 18px;
          font-weight: bold;
          color: #fff;
          text-align: center;
          line-height: 60px;
          transition: 0.2s ease-in-out;
        }
        .version-type .item.plus .btn-join {
          bottom: 48px;
          background: #fe6b37;
        }
        .version-type .item .btn-join:hover {
          opacity: 0.8;
        }
        .version-type .item.plus .btn-join:hover {
          background: #e04812;
          opacity: 1;
        }
        .joinus .section2 .price-area .item {
          background: #fff;
        }
        .joinus .section2 .price-area .item.plus {
          border: 2px solid #fe6b37;
        }
        .joinus .section2 .price-area .item + .item {
          margin-top: 20px;
        }
        .joinus .section2 .price-area .item:after {
          content: "";
          display: block;
          clear: both;
        }
        .joinus .section2 .price-area .item .col {
          float: left;
          width: 25%;
          height: 200px;
          padding-top: 35px;
          border-left: 1px solid #ddd;
          text-align: center;
        }
        .joinus .section2 .price-area .item .col.title {
          padding-top: 47px;
          border-left: 0;
        }
        .joinus .section2 .price-area .item .col.title strong {
          display: block;
          margin-bottom: 3px;
          font-size: 30px;
          font-weight: 300;
          color: #333;
        }
        .joinus .section2 .price-area .item .col.title strong em {
          font-weight: bold;
        }
        .joinus .section2 .price-area .item .col.title span {
          display: block;
          margin-bottom: 10px;
          font-size: 24px;
          font-weight: bold;
          color: #333;
        }
        .joinus .section2 .price-area .item .col.title i {
          display: block;
          font-size: 16px;
          font-weight: 300;
          color: #555;
        }
        .joinus .section2 .price-area .item .col dt {
          display: table;
          width: 100%;
          height: 55px;
          margin-bottom: 10px;
        }
        .joinus .section2 .price-area .item .col dt .middle {
          display: table-cell;
          vertical-align: middle;
        }
        .joinus .section2 .price-area .item .col dt strong {
          display: block;
          font-size: 20px;
          font-weight: bold;
          color: #333;
        }
        .joinus .section2 .price-area .item .col dt span {
          display: block;
          font-size: 16px;
          font-weight: 300;
          color: #333;
        }
        .joinus .section2 .price-area .item .col dd strong {
          display: block;
          font-size: 30px;
          font-weight: bold;
          color: #333;
        }
        .joinus .section2 .price-area .item .col dd span {
          display: block;
          margin-top: 3px;
          font-size: 16px;
          font-weight: 300;
          color: #fe6b37;
        }

        /* Sign Up */
        .signup {
          padding: 121px 0 157px;
        }
        .signup .row {
          margin: 0;
        }
        .signup .section h3 {
          margin-bottom: 15px;
          font-size: 24px;
          font-weight: bold;
          color: #333;
          line-height: 36px;
        }
        .signup .section h3 span {
          display: inline-block;
          margin-left: 13px;
          font-size: 16px;
          font-weight: normal;
          color: #fe4a1c;
          line-height: 36px;
          vertical-align: top;
        }
        .signup .section .inner {
          width: 620px;
          margin: 0 auto;
        }
        .signup .section .row + .row {
          margin-top: 26px;
        }
        .signup .section .input-text.type2:after {
          content: "";
          display: block;
          clear: both;
        }
        .signup .section .input-text.type2 p {
          font-size: 14px;
          font-weight: 500;
          color: #333;
          line-height: 40px;
        }
        .signup .section .input-text.type2 p + ul {
          margin-top: 8px;
        }
        .signup .section .input-text.type2 ul {
          overflow: hidden;
        }
        .signup .section .input-text.type2 ul li {
          float: left;
          min-width: 25%;
          margin-bottom: 5px;
          padding-right: 22px;
        }
        .signup .section .input-text.type2 ul li.w-auto {
          min-width: auto;
        }
        .signup .section .input-text.type2.route-wrap ul li {
          min-width: 27%;
        }
        .signup .section .clear_div.col-sm-2 {
          display: none;
          min-height: 0;
        }
        .signup .section .col-sm-6 {
          float: none;
          min-height: 0;
          padding: 0;
        }
        .signup .section .info-text {
          margin: 0 !important;
          padding: 3px 0 !important;
          font-size: 12px;
          white-space: nowrap;
        }
        .signup .section .col-sm-6 + label {
          margin-top: 7px;
        }
        .signup .section1 {
          margin-bottom: 46px;
        }
        .signup .section1 p {
          font-size: 16px;
          color: #333;
          line-height: 1.6;
        }
        .signup .section2 p {
          margin-bottom: 20px;
          font-size: 16px;
          color: #333;
          line-height: 1.6;
        }
        .signup .section2 + .section2 {
          margin-top: 63px;
        }
        .detail-toggle {
          display: none;
          padding: 20px;
          background: #f2f2f2;
        }
        .detail-toggle .input-text.type2 {
          padding-left: 0;
        }
        .detail-toggle .input-text.type2 input {
          width: 100%;
        }
        .detail-toggle.on {
          display: block;
        }
        .detail-toggle .route {
          display: none;
        }
        .detail-toggle .route.on {
          display: block;
        }
        .certify-input {
          position: relative;
        }
        .certify-input input {
          padding-right: 45px !important;
        }
        .certify-input .count {
          position: absolute;
          top: 0;
          left: 142px;
          font-size: 14px;
          color: #fe4a1c;
          line-height: 35px;
        }
        .signup .btn-question {
          margin-top: 5px;
          padding-left: 20px;
          background: url("/public/assets/pc/images/icon/icon_question1.webp")
            no-repeat left center;
          font-size: 14px;
          color: #333;
          line-height: 18px;
          text-decoration: underline;
        }
        .signup .section3 {
          margin-top: 63px;
        }

        .signup .section3 .row + .row {
          margin-top: 0;
        }
        .signup .section3 .row h4 {
          font-size: 16px;
          font-weight: bold;
          color: #676767;
          line-height: 40px;
        }
        .signup .section3 .row .content {
          overflow-y: auto;
          height: 184px;
          padding: 20px;
          border: 1px solid #979797;
          border-radius: 3px;
          font-size: 12px;
          color: #676767;
        }
        .signup .section3 .row .content pre {
          overflow: hidden;
          width: 100%;
          background: #fff;
          white-space: pre-wrap;
        }
        .signup .section3 .row .content pre strong {
          font-weight: bold;
        }
        .signup .section3 .row .content pre table {
          width: 100% !important;
          max-width: 100%;
          margin: 5px 0;
        }
        .signup .section3 .row .content pre table th,
        .signup .section3 .row .content pre table td {
          padding: 5px;
          border: 1px solid #000;
        }
        .signup .section3 .row .input-checkbox {
          text-align: center;
        }
        .signup .section3 .row .input-checkbox span {
          display: inline-block;
        }
        .signup .btn-wrap {
          margin-top: 43px;
          font-size: 0;
          text-align: center;
        }
        .signup .btn-wrap a {
          display: inline-block;
          width: 160px;
          height: 45px;
          border-radius: 3px;
          font-size: 14px;
          font-weight: bold;
          color: #fff;
          text-align: center;
          line-height: 45px;
        }
        .signup .btn-wrap .btn-cancel {
          background: #35206a;
        }
        .signup .btn-wrap .btn-confirm {
          margin-left: 10px;
          background: #ff663f;
        }
        .signup .btn-wrap .btn-confirm.disabled {
          background: #ccc;
          color: #fff;
        }

        /* =================================================
    * Footer
================================================= */
        #footer {
          padding: 58px 0 75px;
          background: #242426;
        }
        #footer .inner {
          position: relative;
        }
        #footer .copyright {
          width: 670px;
        }
        #footer .copyright p {
          margin-left: -15px;
          line-height: 1.6;
        }
        #footer .copyright p + p {
          margin-top: 20px;
        }
        #footer .copyright p span {
          display: inline-block;
          margin-left: 15px;
          font-size: 14px;
          font-weight: 300;
          color: #9d9d9d;
        }
        #footer .sns-app {
          position: absolute;
          top: 0;
          right: 0;
        }
        #footer .sns-app .sns {
          margin-bottom: 20px;
          text-align: right;
          font-size: 0;
        }
        #footer .sns-app .sns p {
          display: inline-block;
          position: relative;
          margin-right: 15px;
          padding-right: 16px;
          font-size: 14px;
          font-weight: bold;
          color: #9d9d9d;
          line-height: 25px;
          vertical-align: top;
        }
        #footer .sns-app .sns p:after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          width: 1px;
          height: 12px;
          margin-top: -6px;
          background: #5c5c5c;
        }
        #footer .sns-app .sns a {
          opacity: 0.8;
          transition: 0.2s ease-in-out;
        }
        #footer .sns-app .sns .btn-youtube {
          display: inline-block;
          width: 35px;
          height: 29px;
          text-indent: -9999em;
          vertical-align: top;
        }
        #footer .sns-app .sns .btn-blog {
          display: inline-block;
          width: 35px;
          height: 29px;
          margin-left: 25px;
          text-indent: -9999em;
          vertical-align: top;
        }
        #footer .sns-app .sns a:hover {
          opacity: 1;
        }
        #footer .sns-app .app-download {
          margin-top: 29px;
          font-size: 0;
          text-align: right;
        }
        #footer .sns-app .app-download a {
          display: inline-block;
          width: 153px;
          height: 40px;
          border: 1px solid #5c5c5c;
          border-radius: 3px;
          text-indent: -9999em;
          vertical-align: top;
          transition: 0.2s ease-in-out;
        }
        #footer .sns-app .app-download .btn-googleplay {
          margin-top: 10px;
        }
        #footer .sns-app .app-download .btn-appstore {
          margin: 10px 0 0 10px;
        }
        #footer .sns-app .app-download a:hover {
          border-color: #fff;
        }
        #footer .sns-app .app-download .btn-remote-control {
          width: 50px;
          height: 50px;
          margin-left: 20px;

          background-size: 50px 50px;
          border: 0;
          text-indent: -9999em;
        }

        /* =================================================
	* Media Query
================================================= */
      `}</style>
      {children}
    </>
  );
};
