import type { ReactNode } from "react";
import * as 장치 from "../components/장치";
import { match } from "ts-pattern";
import { F } from "@mobily/ts-belt";
import { MobileLayout } from "./MobileLayout";
import { DesktopLayout } from "./DesktopLayout";

export type LayoutSplitterProps = {
  장치: 장치.t;
  pc: ReactNode;
  mobile: ReactNode;
};
export const LayoutSplitter = ({ 장치, pc, mobile }: LayoutSplitterProps) => {
  return match(장치)
    .with("모바일", F.always(<MobileLayout>{mobile}</MobileLayout>))
    .otherwise(F.always(<DesktopLayout>{pc}</DesktopLayout>));
};
